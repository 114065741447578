@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(100%, 0%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes moving-gradient {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right bottom;
  }
}

.fadeInUp,
.fadeInDown,
.fadeInLeft,
.fadeInRight {
  opacity: 0;
  animation-duration: 0.5s;
  animation-name: fadeInUp;
  animation-fill-mode: forwards;
}

.fadeInUp {
  animation-name: fadeInUp;
}
.fadeInDown {
  animation-name: fadeInDown;
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes gradient {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
