.footerSection {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0px 50px;
}

.footerBreak {
  display: none;
}

@media (max-width: 1000px) {
  .footerSocialSection {
    display: block;
  }
}

@media (max-width: 750px) {
  .footerBreak {
    display: block;
  }

  .footerSocialSection {
    display: inline-block;
  }
}

@media (max-width: 530px) {
  .footerSection {
    margin: 30px;
    display: block;
  }
}
