/* PAGE */

* {
  outline: none !important;
}

:root {
  --warning: #ffd131;
  --warning-secondary: #ffc901;
  --danger: #e74c3c;
  --danger-secondary: #d81f64;
  --primary: #2196f4;
  --primary-secondary: #06397d;
  --info: #4fb0f5;
  --info-secondary: #2867b9;
  --success: #2ecc71;
  --success-secondary: #3a993d;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-family: 'Roboto', 'Arial Narrow', sans-serif;
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding: 45px 20px;
}

.rankToolTip {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.infoIcon:hover ~ .rankToolTip {
  display: block;
}

.btn > * {
  height: 100%;
}

.btn + .btn {
  margin-left: 16px;
}

.link {
  text-decoration: none;
  color: var(--primary);
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-default {
  color: #030f23;
}

.text-muted {
  color: #9b9b9b;
}

.text-success {
  color: var(--success);
}

.text-info {
  color: var(--info);
}

.text-warning {
  color: var(--warning);
}

.text-danger {
  color: var(--danger);
}

.text-primary {
  color: var(--primary);
}

.text-thin {
  font-weight: 100;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-semi {
  font-weight: 500;
}

.text-semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.fz-xs {
  font-size: 12px;
}

.fz-sm {
  font-size: 14px;
}

.fz-md {
  font-size: 16px;
}

@media (max-width: 767px) {
  .fz-md {
    font-size: 14px;
  }
}

.fz-lg {
  font-size: 20px;
}

@media (max-width: 767px) {
  .fz-lg {
    font-size: 14px;
  }
}

.fz-xl {
  font-size: 24px;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .fz-xl {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  h1,
  .fz-xl {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 18px;
  }
  .fz-xl {
    font-size: 16px;
  }
}

.border-bottom {
  border-bottom: 1px solid #eee;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.cut-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cut-text-xs {
  max-width: 128px;
}

.cut-text-sm {
  max-width: 200px;
}

.cut-text-md {
  max-width: 250px;
}

.cut-text-lg {
  max-width: 330px;
}

.cut-text-xxl {
  max-width: 600px;
}

.icon-left {
  margin-left: 8px;
}

.flex {
  display: flex;
}

@media (min-width: 768px) {
  .flex-sm {
    display: flex;
  }
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.self-end {
  align-self: flex-end;
}
.self-start {
  align-self: flex-start;
}

@media (max-width: 767px) {
  .grid-xs {
    display: grid;
    grid-gap: 15px 0;
    margin: 15px 0 5px;
  }

  .grid-column-two-xs {
    grid-template-columns: 1fr 1fr;
  }

  .my-arena .oponent-block-record,
  .grid-row-landing-xs {
    grid-column: 1 / 3;
  }

  .grid-row-landing-xs .btn {
    min-width: 100%;
  }
}

.subtitle-line {
  width: 40px;
  height: 2px;
  background-color: var(--primary);
  margin: 0 auto;
  display: block;
}

.text-center .btn {
  margin: auto;
}

.text-right {
  text-align: right;
}

.subtitle-line + .btn {
  margin-top: 24px;
}

.container-xs {
  padding-top: 24px;
  padding-bottom: 24px;
}

.container-md {
  max-width: 992px;
}

.btn-clear {
  padding: 0;
  width: auto;
  height: auto;
  border: 0;
}

.btn-clear:hover > div,
.btn-clear:hover {
  background-color: transparent;
  opacity: 0.8;
}

.btn:active {
  opacity: 1;
}

.btn-muted {
  color: #ccc;
  background-color: transparent;
}

.btn-xs {
  font-size: 15px;
}

.direction-column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

@media (min-width: 1200px) {
  .row-reverse-min-md {
    flex-direction: row-reverse;
  }
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.landing {
  width: 100%;
}

.dib {
  display: inline-block;
}

@media (min-width: 0px) {
  .scroll-from-xs {
    overflow: hidden;
    overflow-x: auto;
  }
}

@media (min-width: 768px) {
  .scroll-from-sm {
    overflow: hidden;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .leaderTooltip,
  .cooldown {
    right: 0px;
  }
  .captainTooltip {
    left: 0px;
  }
}

@media (min-width: 992px) {
  .scroll-from-md {
    overflow: hidden;
    overflow-x: auto;
  }
}

@media (min-width: 1200px) {
  .scroll-from-lg {
    overflow: hidden;
    overflow-x: auto;
  }
}

.mb50 {
  margin-bottom: 50px;
}

/* .tooltip:before {
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  top: -20px;
  border: 10px solid transparent;
  border-bottom-color: #fff;
} */
.no-events {
  pointer-events: none;
}

.nav-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}
