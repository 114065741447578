body {
  color: #030f23;
}

.toast-blue {
  background-color: #2196f3;
  padding: 15px;
  font-weight: bold;
}

.toast-green {
  background-color: #4caf50;
  padding: 15px;
  font-weight: bold;
}

.toast-gray {
  background-color: #757575;
  padding: 15px;
  font-weight: bold;
}

.toast-pink {
  background-color: #e91e63;
  padding: 15px;
  font-weight: bold;
}

.toast-purple {
  background-color: #6b3fb9;
  padding: 15px;
  font-weight: bold;
}

.toast-lime {
  background-color: #9e9d24;
  padding: 15px;
  font-weight: bold;
}

.toast-orange {
  background-color: #ffa000;
  padding: 15px;
  font-weight: bold;
}

.toast-error {
  background-color: #ee5528;
  padding: 15px;
  font-weight: bold;
}
/*body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}*/
